<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.finance_inventory") }}
    </template>
    <template v-slot:contentBody="wrap">
      <div class="mb-2">
        <a-form layout="inline">
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-input
                style="width: 250px"
                v-model:value="data.businessNo"
                :placeholder="$t('finance.business_no')"
                allow-clear
              ></a-input>
            </a-col>
            <a-col>
              <a-select
                v-model:value="data.transactionType"
                style="width: 250px"
                allow-clear
                :placeholder="$t('finance.transaction_type')"
              >
                <a-select-option
                  v-for="(value, key) in transactionTypeEnum"
                  :key="key"
                  :title="$t($enumLangkey('transactionType', value))"
                  :value="value"
                >
                  {{ $t($enumLangkey("transactionType", value)) }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select
                v-model:value="data.businessType"
                style="width: 250px"
                allow-clear
                :placeholder="$t('finance.business_type')"
              >
                <a-select-option
                  v-for="(value, key) in businessTypeEnum"
                  :key="key"
                  :title="$t($enumLangkey('businessType', value))"
                  :value="value"
                >
                  {{ $t($enumLangkey("businessType", value)) }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select
                :allowClear="true"
                show-search
                style="width: 250px"
                optionFilterProp="search-key"
                v-model:value="data.countryId"
                :placeholder="$t('finance.business_country')"
              >
                <a-select-option
                  :value="item.id"
                  v-for="item in data.countrys"
                  :key="item.id"
                  :title="getLanguageName(item) + '(' + item.ioS2 + ')'"
                  :search-key="item.cnName + item.enName + item.ioS2"
                >
                  {{ getLanguageName(item) }}({{ item.ioS2 }})
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select
                :allowClear="true"
                show-search
                style="width: 250px"
                optionFilterProp="search-key"
                v-model:value="data.facilityCode"
                :placeholder="$t('finance.facility_code')"
              >
                <a-select-option
                  :value="item.warehouseNo"
                  v-for="item in data.warehouses"
                  :key="item.id"
                  :title="`${item.warehouseNo}(${item.warehouseName})`"
                  :search-key="item.warehouseName + item.warehouseNo"
                >
                  {{ item.warehouseNo }}({{ item.warehouseName }})
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select
                :allowClear="true"
                show-search
                style="width: 250px"
                optionFilterProp="search-key"
                v-model:value="data.currencyId"
                :placeholder="$t('finance.happen_currency')"
              >
                <a-select-option
                  :value="item.id"
                  v-for="item in data.currency"
                  :key="item.id"
                  :title="`${getLanguageName(item)}(${item.symbol})`"
                  :search-key="
                    item.code + item.symbol + item.cnName + item.enName
                  "
                >
                  {{ getLanguageName(item) }}({{ item.symbol }})
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-range-picker
                style="width: 250px"
                allow-clear
                v-model:value="data.dateRange"
                class="mb-1"
              />
            </a-col>
            <a-col>
              <a-space>
                <a-button @click="handleChangeFilter" type="primary">{{
                  $t("common.query")
                }}</a-button>
                <a-button
                  type="ghost"
                  :loading="data.downloadLoading"
                  @click="handleDownloadExcel"
                  >{{ $t("common.export") }}</a-button
                >
                <a-button
                  type="ghost"
                  @click="handleShowExportStorageFeeModal"
                  >{{ $t("common.export_storage_fee_details") }}</a-button
                >
              </a-space>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 表格 -->
      <div>
        <a-table
          :columns="columns"
          :data-source="data.list"
          :scroll="{ x: 500, y: wrap.contentHeight - 150 }"
          :pagination="false"
          :loading="data.loading"
          size="small"
        >
          <template #time>
            <span> {{ $t("warehouse.time") }} </span>
          </template>
          <template #businessNumber>
            <span> {{ $t("warehouse.business_number") }} </span>
          </template>
          <template #transactionType>
            <span> {{ $t("finance.transaction_type") }} </span>
          </template>
          <template #businessType>
            <span> {{ $t("finance.business_type") }} </span>
          </template>

          <template #happenWarehouseCode>
            <span> {{ $t("finance.facility_code") }} </span>
          </template>
          <template #businessHappenCountry>
            <span> {{ $t("finance.business_country") }} </span>
          </template>
          <template #happenCurrency>
            <span> {{ $t("finance.happen_currency") }} </span>
          </template>
          <template #billNumber>
            <span> {{ $t("finance.bill_number") }} </span>
          </template>
          <template #money>
            <span> {{ $t("finance.money") }} </span>
          </template>

          <template #businessNumberV="{ record }">
            <CPlanNumberItem :no="record.businessNumber"></CPlanNumberItem>
          </template>

          <template #timeCustom="{ record }">
            {{ $filters.utcToCurrentTime(record.time) }}
          </template>

          <template #transactionTypeCustom="{ record }">
            {{ $t($enumLangkey("transactionType", record.transactionType)) }}
          </template>
          <template #businessTypeCustom="{ record }">
            {{ $t($enumLangkey("businessType", record.businessType)) }}
          </template>

          <template #businessHappenCountryCustom="{ record }">
            <span v-if="record.countryCnName && record.countryEnName">
              {{
                getLanguageName({
                  cnName: record.countryCnName,
                  enName: record.countryEnName,
                })
              }}
            </span>
            <span v-else>-</span>
          </template>
          <template #happenWarehouseCodeCustom="{ record }">
            {{ record.happenWarehouseCode || '-' }}
          </template>
          <template #happenCurrencyCustom="{ record }">
            {{ record.currencyCode || '-' }}
          </template>

          <template #moneyCustom="{ record }">
            <span :class="[record.amount >= 0 ? 'text-success' : 'text-error']">
              {{
                $filters.formatCurrencyAmount(
                  record.currencySymbol,
                  record.amount
                )
              }}
            </span>
          </template>
          <template #detailsCustom="{ record }">
            <router-link :to="'/finance/inventory/details/' + record.id">
              <a-button type="ghost">{{ $t("common.details") }}</a-button>
            </router-link>
          </template>
        </a-table>
      </div>

      <a-modal
        v-model:visible="exportStorageFeeModal.visible"
        :centered="true"
        :maskClosable="false"
        :confirmLoading="exportStorageFeeModal.loading"
        :title="$t('common.export_storage_fee_details')"
        :footer="null"
      >
        <a-form :model="exportStorageFeeModal" :label-col="{ span: 4 }">
          <a-form-item :label="$t('warehouse.target_warehouse')">
            <a-select
              :allowClear="true"
              show-search
              style="width: 250px"
              optionFilterProp="search-key"
              v-model:value="exportStorageFeeModal.warehouseId"
            >
              <a-select-option
                :value="item.id"
                v-for="item in data.warehouses"
                :key="item.id"
                :title="`${item.warehouseNo}(${item.warehouseName})`"
                :search-key="item.warehouseName + item.warehouseNo"
              >
                {{ item.warehouseNo }}({{ item.warehouseName }})
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label="$t('warehouse.time')">
            <a-month-picker
              placeholder=""
              v-model:value="exportStorageFeeModal.beginMonthDate"
            />
          </a-form-item>
        </a-form>

        <a-row type="flex" justify="end" :gutter="[16, 8]" class="mt-3">
          <a-col>
            <a-button @click="exportStorageFeeModal.visible = false">{{
              $t("common.cancel")
            }}</a-button>
          </a-col>
          <a-col>
            <a-button
              type="primary"
              @click="handleShowExportStorageFeeModalOk(record)"
              :loading="exportStorageFeeModal.loading"
              :disabled="
                !exportStorageFeeModal.warehouseId ||
                !exportStorageFeeModal.beginMonthDate
              "
              >{{ $t("common.export") }}</a-button
            >
          </a-col>
        </a-row>
      </a-modal>
    </template>
    <!-- 分页 -->
    <template #contentFooter>
      <a-row type="flex" justify="space-around" align="middle">
        <a-col>
          <CPager @handlePage="handlePage" :page-data="data.pageData"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, onActivated } from "vue";
import {
  Table,
  Select,
  Button,
  Row,
  Col,
  Tag,
  Input,
  Form,
  DatePicker,
  Space,
  Modal,
} from "ant-design-vue";
import CPager from "../components/CPager.vue";
import Content from "../components/Content.vue";
import {
  getWarehouses,
  getCountrys,
  getCurrencys,
} from "../../api/modules/common/index.js";
import {
  getList,
  exportList,
  getExportStorageFeeUrl,
} from "../../api/modules/finance/inventory.js";
import {
  transactionType as transactionTypeEnum,
  businessType as businessTypeEnum,
} from "../../enum/enum.json";
import { getName, currentTimeToUtc, dateString } from "../../utils/general";
import { useStore } from "vuex";
import CPlanNumberItem from "../components/CPlanNumberItem.vue";
import { downloadFile } from "@/utils/downloader.js";

const columns = [
  {
    dataIndex: "time",
    fixed: "left",
    slots: {
      title: "time",
      customRender: "timeCustom",
    },
  },
  {
    dataIndex: "businessNumber",
    slots: {
      title: "businessNumber",
      customRender: "businessNumberV",
    },
  },
  {
    dataIndex: "transactionType",
    slots: {
      title: "transactionType",
      customRender: "transactionTypeCustom",
    },
  },
  {
    dataIndex: "businessType",
    slots: {
      title: "businessType",
      customRender: "businessTypeCustom",
    },
  },
  {
    dataIndex: "businessHappenCountry",
    slots: {
      title: "businessHappenCountry",
      customRender: "businessHappenCountryCustom",
    },
  },
  {
    slots: {
      title: "happenWarehouseCode",
      customRender: "happenWarehouseCodeCustom",
    },
  },

  {
    dataIndex: "happenCurrency",
    slots: {
      title: "happenCurrency",
      customRender: "happenCurrencyCustom",
    },
  },

  {
    dataIndex: "money",
    slots: {
      title: "money",
      customRender: "moneyCustom",
    },
  },
  {
    dataIndex: "details",
    slots: {
      customRender: "detailsCustom",
    },
  },
];

export default defineComponent({
  name: "finance_inventory",
  components: {
    CPlanNumberItem,
    ATable: Table,
    Content,
    CPager,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    ARow: Row,
    ACol: Col,
    ATag: Tag,
    AInput: Input,
    AForm: Form,
    ARangePicker: DatePicker.RangePicker,
    AFormItem: Form.Item,
    ASpace: Space,
    AModal: Modal,
    AMonthPicker: DatePicker.MonthPicker,
  },
  setup() {
    const data = reactive({
      loading: false,
      list: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
      businessNo: null, //业务编号
      transactionType: null, //交易类型
      businessType: null, //业务类型
      countryId: null, //业务发生国家
      facilityCode: null, //业务发生国家
      currencyId: null, //发生币种
      dateRange: [],
      downloadLoading: false,
    });
    const dropDownData = reactive({
      countrys: [], //国家
      currency: [], //币种
      warehouses: [],
    });

    const { getters } = useStore();

    const handlePage = async (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      handleSearch();
    };

    const handleChangeFilter = () => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      handleSearch();
    };

    const handleSearch = async () => {
      data.loading = true;
      let beginTime, endTime;
      if (data.dateRange.length > 0) {
        beginTime = currentTimeToUtc(
          data.dateRange[0]?.format("YYYY-MM-DD 00:00:00")
        );
        endTime = currentTimeToUtc(
          data.dateRange[1]?.format("YYYY-MM-DD 00:00:00")
        );
      }
      let parameter = {
        startTime: beginTime,
        endTime: endTime,
        ...data,
        ...data.pageData,
      };
      getList(parameter)
        .then((res) => {
          if (res.result) {
            let { items, totalCount } = res.result;
            data.list = items.map((x, index) => {
              return {
                key: index,
                id: x.id,
                time: x.creationTime,
                businessNumber: x.businessNo,
                transactionType: x.transactionType,
                businessType: x.businessType,
                countryEnName: x.countryEnName,
                countryCnName: x.countryCnName,
                happenWarehouseCode: x.warehouseNo,
                happenCurrency: x.trackNo,
                currencyCode: x.currencyCode,
                currencySymbol: x.currencySymbol,
                amount: x.changeAmount,
              };
            });
            data.pageData.totalCount = parseInt(totalCount);
          } else {
            data.pageData.totalCount = 0;
          }
          data.loading = false;
        })
        .catch(() => {
          data.loading = false;
        });
    };

    const funcGetAllWarehouse = () => {
      getWarehouses({}).then((res) => {
        if (res.result) {
          data.warehouses = res.result;
        }
      });
    };

    const funcGetCountrys = () => {
      getCountrys({}).then((res) => {
        if (res.result) {
          data.countrys = res.result;
        }
      });
    };

    const funcGetCurrency = () => {
      getCurrencys({}).then((res) => {
        if (res.result) {
          data.currency = res.result;
        }
      });
    };

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    };

    const handleDownloadExcel = () => {
      data.downloadLoading = true;
      let url = exportList();
      let beginTime = null,
        endTime = null;
      if (data.dateRange.length > 0) {
        beginTime = currentTimeToUtc(
          data.dateRange[0]?.format("YYYY-MM-DD 00:00:00")
        );
        endTime = currentTimeToUtc(
          data.dateRange[1]?.format("YYYY-MM-DD 00:00:00")
        );
      }
      const postDataObj = {
        businessNo: null,
        transactionType: null,
        businessType: null,
        countryId: null,
        facilityCode: null,
        currencyId: null,
        startTime: null,
        endTime: null,
        skipCount: null,
        maxResultCount: null,
        sorting: null,
      };
      for (const key in data) {
        if (Object.hasOwnProperty.call(postDataObj, key)) {
          postDataObj[key] = data[key];
        }
      }
      const postData = Object.assign({}, postDataObj, {
        startTime: beginTime,
        endTime: endTime,
      });

      downloadFile(
        url,
        `finance_inventory_${dateString()}.csv`,
        "POST",
        postData
      )
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          data.downloadLoading = false;
        });
    };

    //---------------------------start 导出存储费明细 start------------------------------------------------------
    const exportStorageFeeModal = reactive({
      loading: false,
      visible: false,
      warehouseId: null,
      beginMonthDate: null,
    });

    const handleShowExportStorageFeeModal = () => {
      exportStorageFeeModal.visible = true;
    };

    const handleShowExportStorageFeeModalOk = () => {
      exportStorageFeeModal.loading = true;
      let url = getExportStorageFeeUrl();
      let postData={...exportStorageFeeModal};
      downloadFile(
        url,
        `storage_fee_details_${dateString()}.csv`,
        "POST",
        postData
      )
        .then(() => {
          exportStorageFeeModal.visible = false;
        })
        .catch(() => {})
        .finally(() => {
           exportStorageFeeModal.loading=false;
        });
    };

    //---------------------------end   导出存储费明细 end------------------------------------------------------

    onMounted(() => {
      funcGetAllWarehouse();
      funcGetCountrys();
      funcGetCurrency();
    });

    onActivated(() => {
      handleSearch();
    });

    return {
      columns,
      transactionTypeEnum,
      businessTypeEnum,
      dropDownData,
      data,
      handleSearch,
      handlePage,
      handleChangeFilter,
      getLanguageName,
      handleDownloadExcel,

      exportStorageFeeModal,
      handleShowExportStorageFeeModal,
      handleShowExportStorageFeeModalOk,
    };
  },
});
</script>
<style lang="less" scoped>
.moneyColor {
  color: red;
}
</style>
